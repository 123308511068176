
import CableReady from 'cable_ready'
import consumer from './consumer'

consumer.subscriptions.create('LeadChannel', {
  received (data) {
    var audio = document.getElementById("myAudio");
    if(audio){
      var a = new Audio('https://phonefixhd.co.uk/not.mp3');
      a.play();
      setTimeout(() => { window.location.href = "/list"; }, 2000);
    }
  }
})
